import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import MiniHeader from './MiniHeader';
import * as styles from './EinzelLeistung.module.css';

/* die eingehenden props enthalten nur die ID der einzelleistung */
function EinzelLeistung(props) {
    const data = useStaticQuery(graphql`
        query {
            configJson {
                leistungen {
                    head
                    id
                    kurz
                    lang
                    url
                }
            }
        }
    `)   

    const id = props.id;
    const leistung = data.configJson.leistungen[id-1];
    const imgPath = "/images/";

    return (
        <div>
            <MiniHeader headline={leistung.head}/>
            <div className={styles.elContainer}>
                <div className={styles.elWrapper}>
                    <img className={styles.elImage} src={`${imgPath}${leistung.url}`} alt={leistung.head}/>    
                    <div className={styles.elTextContainer}>
                         <p className={styles.elText}>{leistung.lang}</p>
                    </div>
                </div>
                <p onClick={() => window.history.back()} className="defaultBtn">zurück</p>
            </div>
        </div>
    )
}

export default EinzelLeistung
