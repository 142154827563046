import React from 'react';
import * as styles from './MiniHeader.module.css';

function MiniHeader(props) {
    return (
        <div className={styles.miniContainer}>
            <div className={styles.miniWrapper}>
                <h2 className={styles.miniHeadline}>{props.headline}</h2>
            </div>
        </div>
    )
}

export default MiniHeader
